import React from 'react'
import { Hero } from '../../lib'

export default function BlogIndexHeader() {
  return (
    <Hero
      noBtn
      line1="Articles about development"
      line2="Design & other tech topics"
      height={350}
    />
  )
}
