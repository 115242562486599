import React from "react"
import styled from "styled-components"
import Layout_main from "../layouts/Layout_main.js"
import BlogIndexHeader from "../components/blog/BlogIndexHeader.js"
import BlogIndexSearchCarousel from "../components/blog/BlogIndexSearchCarousel.js"
import BlogIndex from "../components/blog/BlogIndex.js"
import { horizontalWhiteGreyAfterBG } from "../styles/backgrounds.js"
import { Container } from "../styles/containers.js"

export default function BlogIndexPage({ location }) {
	return (
		<Layout_main location={location}>
			<BlogIndexHeader />
			<BlogIndexSearchCarousel />
			<OuterContainer modifiers={["section", "full_width"]}>
				<Container as="div" modifiers={["block", "nested"]}>
					<BlogIndex />
				</Container>
			</OuterContainer>
		</Layout_main>
	)
}

const OuterContainer = styled(Container)`
	${horizontalWhiteGreyAfterBG};
`
