import React from "react"
import styled from "styled-components"
import { Link, StaticQuery, graphql } from "gatsby"
import { Icon } from "../../lib/Icon.js"
import { Anchor } from "../../styles/anchors.js"
import { theme, rgba } from "../../styles/colors.js"
import { elevation } from "../../styles/elevation.js"
import { Para } from "../../styles/paragraphs.js"
import { SmlStrongHeading } from "../../styles/headings.js"

const BLOG_INDEX_QUERY = graphql`
	query BLOG_INDEX_QUERY {
		allMdx(sort: { fields: frontmatter___date, order: DESC }) {
			nodes {
				id
				tableOfContents
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					line1
					line2
					line3
					icon
					image
					image_alt
					hero_bg
					excerpt
				}
			}
		}
	}
`

export default function BlogIndex() {
	return (
		<StaticQuery
			query={BLOG_INDEX_QUERY}
			render={({ allMdx }) =>
				allMdx.nodes.map(node => (
					<Post key={node.fields.slug}>
						<TitleBar>
							<Link to={node.fields.slug}>
								<Icon name={node.frontmatter.icon || "logo"} fill={theme.darkAlt} width={60} height={60} />
								<SmlStrongHeading as="h2">
									{node.frontmatter.line1 || null} {node.frontmatter.line2 || null} {node.frontmatter.line3 || null}
								</SmlStrongHeading>
							</Link>
						</TitleBar>
						<div>
							<Para className="date">{node.frontmatter.date}</Para>
							<Para>{node.frontmatter.excerpt}</Para>
							<StyledAnchor to={node.fields.slug}>Read More</StyledAnchor>
						</div>
					</Post>
				))
			}
		/>
	)
}

const Post = styled.article`
	${elevation[2]};
	border-radius: 4px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 1rem;
	max-width: 900px;

	& > * {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	& > *:first-child {
		padding-top: 1.2rem;
	}

	& > *:last-child {
		padding-bottom: 1rem;
	}

	.date {
		position: relative;
		display: inline-block;
		font-size: 1rem;
		font-weight: 600;
		margin-bottom: 22px;
		white-space: nowrap;
		z-index: 1;

		&:after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: 0%;
			width: 100%;
			height: 0.4rem;
			background: ${theme.grey};
			transform: translate(-50%, -50%);
			z-index: -1;
		}
	}
`

const TitleBar = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 1rem;
	margin-bottom: 18px;
	border-radius: 4px 4px 0 0;
	background: ${rgba(theme.grey, 0.3)};

	h2 {
		display: inline-block;
		margin: 0;

		@media only screen and (min-width: 800px) {
			font-size: 1.765rem;
		}
	}

	a {
		margin-right: 18px;
		display: flex;
		align-items: center;
	}

	a svg {
		margin-right: 22px;
	}
`

const StyledAnchor = styled(Anchor)`
	display: inline-block;
	font-size: 1rem;
	font-weight: 600;
	margin-top: 22px;
	color: ${theme.darkAlt};
	background: ${theme.grey};
	border-radius: 5px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	padding: 5px 10px 4px;
	cursor: pointer;

	&:hover {
		color: ${theme.white};
		background: ${theme.darkAlt};
	}
`
