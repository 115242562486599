import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { ContactBtn, Toggle } from "../../lib"
import { LogoLink } from "../../assets/logos"
import { horizontalWhiteGrey, theme, elevation, Container } from "../../styles"
import { useScrollY, isBrowser } from "../../utils"
import MainNav from "./MainMenu"
import MenuOpenButton from "./MobileMenuOpen"
import MobileMenu from "./MobileMenu"

export default function MainNavBar() {
	const distanceFromTop = useScrollY()
	const [addNavBarTransition, setAddNavBarTransition] = useState(false)
	const [distanceScrolled, setDistanceScrolled] = useState(0)
	const [readyNavBarAnimation, setReadyNavBarAnimation] = useState(false)

	const navBarAnimation = useSpring({
		from: {
			opacity: 0,
			y: -80,
		},
		opacity: 1,
		y: 0,
		tension: 280,
		friction: 38,
		delay: 150,
		onRest: () => setAddNavBarTransition(true),
	})

	// TODO: Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
	// When navigating from home to contact page
	useEffect(() => {
		const applyAnimation = isBrowser && window.innerWidth > 800
		const ready = distanceFromTop > 600

		applyAnimation && ready ? setReadyNavBarAnimation(true) : setReadyNavBarAnimation(false)

		if (applyAnimation) {
			const scrolled = distanceFromTop - distanceScrolled

			if (readyNavBarAnimation && scrolled < -5) {
				document.body.classList.add("to_fixed")
				setTimeout(() => document.body.classList.contains("to_fixed") && document.body.classList.add("show_nav"), 100)
			}

			if (scrolled > -5) {
				document.body.classList.remove("show_nav")
				setTimeout(() => {
					document.body.classList.remove("to_fixed")
				}, 100)
			}

			return () => setDistanceScrolled(distanceFromTop)
		}

		return () => {
			setDistanceScrolled(0)
			setAddNavBarTransition(false)
			setReadyNavBarAnimation(false)
		}
	}, [distanceFromTop])

	return (
		<NavBar
			style={{
				opacity: navBarAnimation.opacity.interpolate(opacity => opacity),
				transform: navBarAnimation.y.interpolate(y => `translate3d(0, ${y}px, 0)`)
					? navBarAnimation.y.interpolate(y => `translate3d(0, ${y}px, 0)`)
					: addNavBarTransition && readyNavBarAnimation && "translate3d(0, -80px, 0)",
				transition: addNavBarTransition ? "opacity 0.250ms ease, transform 0.3s ease, top 0.3s ease" : "none",
				top: addNavBarTransition && readyNavBarAnimation && "-80px",
				background: readyNavBarAnimation && `linear-gradient(${theme.white}, ${theme.lightestGrey})`,
				boxShadow: readyNavBarAnimation && "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
			}}
		>
			<NavContainer as="div">
				<div>
					<LogoLink name="logo" fill={theme.darkAlt} />
					<MainNav />
				</div>
				<ContactBtn modifiers={["width_auto", "light"]}>Contact</ContactBtn>
				<Toggle>
					{({ on, toggle }) => (
						<>
							<MenuOpenButton on={on} toggle={toggle} />
							<MobileMenu on={on} toggle={toggle} />
						</>
					)}
				</Toggle>
			</NavContainer>
		</NavBar>
	)
}

const NavBar = styled(animated.nav)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	&:after {
		content: "";
		transition: all 250ms ease;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.75;
		height: 100%;
		width: 100%;
		background: ${horizontalWhiteGrey};
		${elevation[2]};
		z-index: -1;
	}

	&:hover {
		&:after {
			opacity: 1;
		}
	}

	.to_fixed & {
		position: fixed;
		opacity: 0 !important;
		top: -80px !important;
		transform: translate3d(0, -80px, 0) !important;
	}

	.show_nav & {
		opacity: 1 !important;
		top: 0px !important;
		transform: translate3d(0, 0px, 0) !important;
		background: ${horizontalWhiteGrey} !important;
		${elevation[2]};
	}
`

const NavContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 70px;

	div {
		display: flex;
		align-items: center;
	}

	& > a {
		@media only screen and (max-width: 800px) {
			display: none;
		}
	}
`
