import React from 'react'
import styled from 'styled-components'
// import Carousel from 'looped-react'
// import { Icon } from '../../lib'
import { isBrowser } from '../../utils'
import { Container, theme } from '../../styles'
import Search from './Search'

const config = {
  transitionDuration: 5520,
  easing: 'ease-out',
  perPage: {
    w300: 3,
    w575: 5,
    w775: 6,
    w1100: 7,
  },
  draggable: true,
  multipleDrag: true,
  intervalDuration: 4500,
  on: false,
  onInit() {
    this.next()
  },
}

export default function BlogIndexSearchCarousel() {
  return (
    <Container background={theme.grey} modifiers={['full_width', 'nested']}>
      {isBrowser && (
        <>
          <ContainerStyled as="div" modifiers={['noPadTop', 'noPadBottom']}>
            <Search />
          </ContainerStyled>
          <ContainerStyled as="div" modifiers={['noPadTop', 'noPadBottom']}>
            {/* <Carousel config={config}>
              <Icon name="wordpress" width={75} height={75} />
              <Icon name="react" width={75} height={75} />
              <Icon name="apollo" width={75} height={75} />
              <Icon name="javascript" width={75} height={75} />
              <Icon name="gatsby" width={75} height={75} />
              <Icon name="next" width={120} height={75} />
              <Icon name="figma" width={75} height={75} />
              <Icon name="xd" width={75} height={75} />
              <Icon name="css" width={75} height={75} />
              <Icon name="html" width={75} height={75} />
            </Carousel> */}
          </ContainerStyled>
        </>
      )}
    </Container>
  )
}

const ContainerStyled = styled(Container)`
  padding-top: 22px;
  padding-bottom: 22px;

  svg {
    display: block;
    margin: 0 auto;
  }
`
