import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { SkipToContentBtn, ScrollToTopBtn } from "../lib"
import Head from "./Head"
import MainNavBar from "./navigation/MainNavBar"
import Footer from "./footer/Footer"
import Socket from "./footer/Socket"
import "../styles/fonts/index.css"
import "../styles/main.scss"

export default function Layout_main(props) {
	const { children, location } = props

	return (
		<>
			<DocumentHead location={location} />
			<SkipToContentBtn />
			<MainNavBar location={location} />
			<main id="content">{children}</main>
			<Footer location={location} />
			<Socket />
			<ScrollToTopBtn />
		</>
	)
}

function DocumentHead({ location }) {
	return (
		<StaticQuery
			query={graphql`
				query HEAD_QUERY {
					site {
						siteMetadata {
							title
						}
					}
				}
			`}
			render={data => <Head data={data} location={location} />}
		/>
	)
}
